<template>
  <div class="global-loading">
    <div id="loaderspinwrap" class="loaderspinwrap">
      <div class="loaderspin"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalLoading',
};
</script>

<style lang="scss">
.global-loading {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  /deep/ .loaderspin {
    margin: 0 auto;
    width: 80px;
    height: 80px;
    border: 10px solid #f083a6;
    border-top: 10px solid #b22655;
    border-right: 10px solid #b22655;
  }
}
</style>
